var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "work-order-list-component table-component" },
    [
      _c("div", { staticClass: "page-header" }, [
        _c(
          "div",
          { staticClass: "page-breadcrumb" },
          [
            _c(
              "a-breadcrumb",
              [
                _c(
                  "a-breadcrumb-item",
                  [_c("router-link", { attrs: { to: "/" } }, [_vm._v("首页")])],
                  1
                ),
                _c("a-breadcrumb-item", [_vm._v("工单管理")]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "page-body page-body-margin" },
        [
          _c(
            "a-card",
            { staticClass: "table-query-card", attrs: { title: "工单查询" } },
            [
              _c("jtl-table-query-component", {
                ref: _vm.JTL.CONSTANT.DEFAULT_TABLE_QUERY_NAME,
              }),
            ],
            1
          ),
          _c(
            "a-card",
            { attrs: { title: "工单列表" } },
            [
              _vm.menuType === "All" &&
              _vm.workorderType !== _vm.JTL.CONSTANT.ALL_OPTION.value
                ? _c(
                    "jtl-button",
                    {
                      staticClass: "table-header-button",
                      attrs: { slot: "extra", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.exportClick()
                        },
                      },
                      slot: "extra",
                    },
                    [_vm._v("导出")]
                  )
                : _vm._e(),
              _c("a-table", {
                attrs: {
                  loading: _vm.listLoading,
                  columns: _vm.tableColumns,
                  "data-source": _vm.listData,
                  pagination: _vm.Pagination,
                  scroll: _vm.tableScroll,
                  "row-key": "id",
                  size: _vm.TableSize,
                },
                on: { change: _vm.tableChange },
                scopedSlots: _vm._u([
                  {
                    key: "action",
                    fn: function (text, record) {
                      return [
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.showModal(
                                  _vm.ViewModeType.VIEW,
                                  record
                                )
                              },
                            },
                          },
                          [_vm._v("查看")]
                        ),
                        _vm.menuType === "AssignedTo" ||
                        _vm.menuType === "ToClaim"
                          ? _c("a-divider", { attrs: { type: "vertical" } })
                          : _vm._e(),
                        _vm.menuType === "AssignedTo"
                          ? _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.showModal(
                                      _vm.ViewModeType.UPDATE,
                                      record
                                    )
                                  },
                                },
                              },
                              [_vm._v("处理")]
                            )
                          : _vm.menuType === "ToClaim"
                          ? _c(
                              "a-popconfirm",
                              {
                                attrs: {
                                  title: "确认认领?",
                                  "ok-text": "确认",
                                  "cancel-text": "取消",
                                },
                                on: {
                                  confirm: function ($event) {
                                    return _vm.claimWorkOrder(record)
                                  },
                                },
                              },
                              [_c("a", [_vm._v("认领")])]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-drawer",
        {
          attrs: {
            placement: _vm.placement,
            width: _vm.width,
            closable: _vm.closable,
            visible: _vm.drawerVisible,
            "destroy-on-close": true,
          },
          on: { close: _vm.drawerClose },
        },
        [
          _c(
            "div",
            {
              style: {
                backgroundColor: "#05BC9D",
                width: "100px",
                padding: "10px 0 10px 15px",
                borderRadius: "0px 50px 50px 0px",
                color: "white",
              },
              attrs: { slot: "title" },
              slot: "title",
            },
            [_vm._v(" 工单详情  ")]
          ),
          _c("work-order-detail-component", {
            attrs: {
              "view-type": _vm.viewType,
              "wo-config-id": _vm.selectedWoConfigId,
              "wo-id": _vm.selectedWorkOrderId,
            },
            on: { showDrawer: _vm.showDrawer },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }